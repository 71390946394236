import Vue from "vue";
import Vuex from "vuex";
import CryptoJS from "crypto-js";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    decryptionResult: "",//解密文本
    encryptedText: "",//加密文本
    key: CryptoJS.enc.Utf8.parse("4ed5666f5b665802524d7aef52a05bc6"),
    iv: CryptoJS.enc.Utf8.parse("4ed5666f5b665802"),
    userInfo: {}
  },
  getters: {
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo
    },
  },
  mutations: {
    //aes加密
    encrypt(state, word) {
      if (typeof word == 'object') {
        word = JSON.stringify(word)
      } else {
        word = word.toString()
      }
      let srcs = CryptoJS.enc.Utf8.parse(word);
      let encrypted = CryptoJS.AES.encrypt(srcs, state.key, { iv: state.iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
      state.encryptedText = encrypted.ciphertext.toString().toUpperCase()
    },
    // aes解密
    decrypt(state, word) {
      const encryptedHexStr = CryptoJS.enc.Hex.parse(word);
      const srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
      const decrypt = CryptoJS.AES.decrypt(srcs, state.key, {
        iv: state.iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
      const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
      state.decryptionResult = decryptedStr.toString()
    }
  },
  actions: {
    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        this.$http({
          url: '/UserInfo/getUserInfor',
          method: "post",
          data: {
            id: localStorage.getItem('id')
          }
        }).then(res => {
          const user = res.data.data[0]
          commit('SET_USERINFO', user)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
  modules: {},
});
