import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from 'vant';
import 'vant/lib/index.css';
//引入axios
import axios from './utils/http';
//引入iconfont
import '@/assets/font/iconfont.css';
//引入懒加载
import { Lazyload } from "vant";
import dayjs from "dayjs"


//全局注册
import Loading from './components/loading.vue';
Vue.component('Loading', Loading);
Vue.prototype.dayjs = dayjs;//可以全局使用dayjs


Vue.use(Vant);
Vue.use(Lazyload);
Vue.prototype.$http = axios
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
