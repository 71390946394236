import axios from 'axios'
import { base_url } from './config'
import { Toast } from 'vant';
axios.defaults.baseURL = base_url;
axios.interceptors.request.use(function (config) { //当所有axios发送请求的时候进行拦截
  //获取token
  let token = localStorage.getItem('token') || '4ed5666f5b6658026e385ba26a215f0f672a767b5f5572b66001';
  //有没有token,有的话就加在request里面
  token && (config.headers['token'] = token);
  return config;

}, function (error) {
  return Promise.reject(error);
});
axios.interceptors.response.use(function (response) {//所有axios的响应进行拦截
  return response;
}, function (error) {
  if (error.response.status == 401) { //token失效了
    Toast({
      message: "登录失效，请重新登录",
      position: "bottom"
    })
    window.location.href = '/#/login';

  }

  return Promise.reject(error);
});


export default axios;