import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: '/',
  //   redirect: '/home',
  // },
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
    meta: { title: "仕景学堂 | 首页" }
  },
  {
    path: "/swiper-details",
    name: "swiperDetails",
    component: () => import("../views/SwiperDetails.vue"),
    meta: { title: "仕景学堂 | 详情" }
  },
  {
    path: "/hot",
    name: "hot",
    component: () => import("../views/HotView.vue"),
    meta: { title: "仕景学堂 | 热点" }
  },
  {
    path: "/hotDetail",
    name: "hotDetail",
    component: () => import("../views/HotDetails.vue"),
    meta: { title: "仕景学堂 | 热点详情" }
  },
  {
    path: "/paper",
    name: "paper",
    component: () => import("../views/PaperView.vue"),
    meta: { title: "仕景学堂 | 题库" }
  },
  {
    path: "/readyExam",
    name: "readyExam",
    component: () => import("../views/ReadyExam.vue"),
    meta: { title: "仕景学堂 | 考试须知" }
  },
  {
    path: "/mine",
    name: "mine",
    component: () => import("../views/MineView.vue"),
    meta: { title: "仕景学堂 | 我的" }
  },
  {
    path: "/mineInfo",
    name: "mineInfo",
    component: () => import("../views/MineInfo.vue"),
    meta: { title: "仕景学堂 | 我的信息" }
  },
  {
    path: "/learn",
    name: "learn",
    component: () => import("../views/LearnView.vue"),
    meta: { title: "仕景学堂 | 我的学习" }
  },
  {
    path: "/colletion",
    name: "colletion",
    component: () => import("../views/ColletionView.vue"),
    meta: { title: "仕景学堂 | 我的收藏" }
  }, {
    path: "/colletionList",
    name: "colletionList",
    component: () => import("../views/ColletionList.vue"),
    meta: { title: "仕景学堂 | 收藏列表" }
  }, {
    path: "/simulation",
    name: "simulation",
    component: () => import("../views/Simulation.vue"),
    meta: { title: "仕景学堂 | 模拟测试" }
  },
  {
    path: "/ErrListView",
    name: "ErrListView",
    component: () => import("../views/ErrListView.vue"),
    meta: { title: "仕景学堂 | 我的错题" }
  },
  {
    path: "/ErrList",
    name: "ErrList",
    component: () => import("../views/ErrList.vue"),
    meta: { title: "仕景学堂 | 错题列表" }
  },
  {
    path: "/changePassword",
    name: "changePassword",
    component: () => import("../views/ChangePassword.vue"),
    meta: { title: "仕景学堂 | 修改密码" }
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
    meta: { title: "仕景学堂 | 登录账号" }
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/RegisterView.vue"),
    meta: { title: "仕景学堂 | 注册账号" }
  },
  {
    path: "/resetpwd",
    name: "resetpwd",
    component: () => import("../views/ResetPwd.vue"),
    meta: { title: "仕景学堂 | 重置密码" }
  },
  {
    path: "/course-details",
    name: "course-details",
    component: () => import("../views/CourseDetails.vue"),
    meta: { title: "仕景学堂 | 课程详情" }
  },
  {
    path: "/play-video",
    name: "play-video",
    component: () => import("../views/PlayVideo.vue"),
    meta: { title: "仕景学堂 | 观看视频" }
  },
  {
    path: "/ready-do-exercise",
    name: "ready-do-exercise",
    component: () => import("../views/ReadyDoExercise.vue"),
    meta: { title: "仕景学堂 | 练前须知" }
  },
  {
    path: "/do-exercise",
    name: "do-exercise",
    component: () => import("../views/DoExercise.vue"),
    meta: { title: "仕景学堂 | 做练习" }
  },
  {
    path: "/exercise-result",
    name: "exercise-result",
    component: () => import("../views/ExerciseResult.vue"),
    meta: { title: "仕景学堂 | 练习结果" }
  },
  {
    path: "/exam",
    name: "exam",
    component: () => import("../views/ExamView.vue"),
    meta: { title: "仕景学堂 | 考试中" }
  },
  {
    path: "/checkParsing",
    name: "checkParsing",
    component: () => import("../views/CheckParsingView.vue"),
    meta: { title: "仕景学堂 | 查看解析" }
  },
  {
    path: "/examResult",
    name: "examResult",
    component: () => import("../views/ExamResult.vue"),
    meta: { title: "仕景学堂 | 考试结果" }
  },
  {
    path: "/words-type-list",
    name: "words-type-list",
    component: () => import("../views/WordsTypeList.vue"),
    meta: { title: "仕景学堂 | 背单词" }
  },
  {
    path: "/rem-words",
    name: "rem-words",
    component: () => import("../views/RemWords.vue"),
    meta: { title: "仕景学堂 | 背单词" }
  },
  {
    path: "/mold-test",
    name: "mold-test",
    component: () => import("../views/MoldTest.vue"),
    meta: { title: "仕景学堂 | 模考" }
  },
  {
    path: "/test-paper",
    name: "test-paper",
    component: () => import("../views/MyTestPaper.vue"),
    meta: { title: "仕景学堂 | 我的试卷" }
  },
  {
    path: "/video-course",
    name: "video-course",
    component: () => import("../views/VideoCourse.vue"),
    meta: { title: "仕景学堂 | 视频课程" }
  },
  {
    path: "/studyData-type-list",
    name: "studyData-type-list",
    component: () => import("../views/StudyDataTypeList.vue"),
    meta: { title: "仕景学堂 | 学习资料" }
  },
  {
    path: "/study-data",
    name: "study-data",
    component: () => import("../views/StudyData.vue"),
    meta: { title: "仕景学堂 | 学习资料" }
  },
  {
    path: "/exam-outline-type-list",
    name: "exam-outline-type-list",
    component: () => import("../views/examOutlineTypeList.vue"),
    meta: { title: "仕景学堂 | 考试大纲" }
  },
  {
    path: "/exam-outline",
    name: "exam-outline",
    component: () => import("../views/examOutline.vue"),
    meta: { title: "仕景学堂 | 考试大纲" }
  },
  {
    path: "/daily-practice",
    name: "daily-practice",
    component: () => import("../views/DailyPractice.vue"),
    meta: { title: "仕景学堂 | 每日一练" }
  },
  {
    path: "/daily-practice-result",
    name: "daily-practice-result",
    component: () => import("../views/DailyPracticeResult.vue"),
    meta: { title: "仕景学堂 | 每日一练" }
  },
  {
    path: "/daily-practice-checkParsing",
    name: "daily-practice-checkParsing",
    component: () => import("../views/DailyPracticeCheckParsing.vue"),
    meta: { title: "仕景学堂 | 每日一练解析" }
  },
  {
    path: '*',
    component: () => import('../views/pageNotFound.vue'),
    meta: { title: "未知页面" }
  }
];

const router = new VueRouter({
  routes,
});
router.afterEach((to) => {
  window.scrollTo(0, 0);
  document.title = to.meta?.title;
})
export default router;
