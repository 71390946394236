<template>
  <div class="loadingPage" v-show="loading" :class="[fullScreen?'fullScreenClass':'nofullScreen']">
    <van-loading size="1.5rem" vertical>加载中...</van-loading>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: true,
    },
    fullScreen: {
      type: Boolean,
      default: true,
    },
  },
};
</script>


<style lang="less">
.loadingPage {
  position: fixed;
  top: 0;
  left: 0;
  // height: v-bind(loadingheight);
  width: 100vw;
  background: #fff;
  z-index: 9999;
  display: flex;
  justify-content: center;
  padding-top: 10rem;
}
.fullScreenClass {
  height: 100vh;
}
.nofullScreen {
  height: calc(~"100vh - 50px");
}
</style>